@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../node_modules/lato-font/css/lato-font.min.css";

* {
	outline: none !important;
	margin: 0;
	padding: 0;
	scrollbar-width: none;
}

input {
	background-color: transparent;
}

.h-250 {
	height: 300px;
}

html {
	overflow: scroll;
	scrollbar-width: none !important;
}

*::-webkit-scrollbar {
	display: none;
}

* {
	-ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
	scrollbar-width: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

/* The emerging W3C standard
   that is currently Firefox-only */
.show-custom-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
}
.show-custom-scrollbar.custom-scrollbar-invert {
	scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.067);
}

/* Works on Chrome/Edge/Safari */
.show-custom-scrollbar::-webkit-scrollbar {
	display: block;
	width: 5px;
}

.show-custom-scrollbar::-webkit-scrollbar-track {
	display: block;
	background: transparent;
}
.show-custom-scrollbar.custom-scrollbar-invert.custom-scrollbar-track-light::-webkit-scrollbar-track {
	background: #0001;
}

.show-custom-scrollbar::-webkit-scrollbar-thumb {
	display: block;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
}
.show-custom-scrollbar.custom-scrollbar-invert::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.5);
}
.show-custom-scrollbar.custom-scrollbar-thin::-webkit-scrollbar-thumb {
	width: 2px;
	height: 2px;
}

.image-gallery-svg {
	width: 30px !important;
	height: 60px !important;
}

iframe {
	scrollbar-width: none !important;
}

iframe::-webkit-scrollbar {
	width: 5px;
}

iframe::-webkit-scrollbar-track {
	background: white;
}

iframe::-webkit-scrollbar-thumb {
	background-color: gray;
	border-radius: 10px;
	height: 60px;
}

button {
	outline: none !important;
}

body {
	font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: white;
	height: 100vh;
	width: 100vw;
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

ul {
	list-style: none;
}

button,
select {
	outline: none;
	cursor: pointer;
}

/* FIXME: Remove custom styles once we updated the tailwind version. */

.blur {
	filter: blur(4px);
	-webkit-filter: blur(4px);
}

.grayscale {
	filter: grayscale(1);
	-webkit-filter: grayscale(1);
}

.hb-media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.hb-circle-icon-btn div {
	border-color: inherit
}

.hb-comment-content span {
	--text-opacity: 1;
	color: #4299e1;
	color: rgba(66, 153, 225, var(--text-opacity))
}

.hb-user-quote {
	position: relative;
	padding: .75rem .75rem .75rem 1.25rem;
	border-radius: .25rem
}

.hb-user-quote:before {
	position: absolute;
	font-size: 4rem;
	margin-top: -1.5rem;
	margin-left: -.5rem;
	font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
	content: open-quote
}

.hb-user-quote i {
	display: block;
	margin-left: 2rem;
	font-weight: 600
}

.hb-hoot-tile-container {
	display: flex;
	flex-direction: column
}

.hb-hoot-tile-container>* {
	margin-bottom: .5rem
}

.hb-hoot-tile-container>:first-child {
	margin-top: .75rem
}

.hb-hoot-tile-container:first-child:not(.hb-ignore) {
	margin-top: .5rem
}

.hb-hoot-tile-container>:last-child {
	margin-bottom: .75rem
}

.hb-hoot-tile-item {
	margin-left: 1.25rem;
	margin-right: 1.25rem
}

@media (min-width:320px) {
	.hb-hoot-tile-item {
		margin-left: .75rem;
		margin-right: .75rem
	}
}

@media (min-width:640px) {
	.hb-hoot-tile-item {
		margin-left: 1.25rem;
		margin-right: 1.25rem
	}
}

.hb-hoot-display-container {
	display: flex;
	flex-direction: column
}

.hb-title {
	padding-bottom: .25rem
}

.hb-hoot-display-container>:not(.hb-ignore) {
	margin-bottom: 1rem
}

.hb-hoot-display-container>.hb-ignore:not(:last-child) {
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 1rem
}

.hb-hoot-display-container>:first-child {
	margin-top: 1rem
}

.hb-hoot-display-container>:last-child:not(.hb-ignore) {
	margin-bottom: 2rem
}

.hb-hoot-display-item>:not(.hb-ignore) {
	margin-left: 1rem;
	margin-right: 1rem
}

@media (min-width:480px) {
	.hb-hoot-display-item>:not(.hb-ignore) {
		margin-left: 2rem;
		margin-right: 2rem
	}
}

.hb-truncate-1 {
	-webkit-line-clamp: 1
}

.hb-truncate-1,
.hb-truncate-2 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden
}

.hb-truncate-2 {
	-webkit-line-clamp: 2
}

.hb-truncate-3 {
	-webkit-line-clamp: 3
}

.hb-truncate-3,
.hb-truncate-4 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden
}

.hb-truncate-4 {
	-webkit-line-clamp: 4
}

.hb-truncate-5 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	overflow: hidden
}

.hb-text-shadow {
	text-shadow: 1px 1px 2px #333
}

.hb-button-with-bottom-line {
	border-style: solid;
	border-bottom-width: 2px;
	padding: .5rem 1rem;
	margin-right: .5rem;
	font-size: 1rem;
	--border-opacity: 1 !important;
	border-color: #0067f9 !important;
	border-color: rgba(0, 103, 249, var(--border-opacity)) !important
}

.hb-button-with-bottom-line-inactive {
	padding: .5rem 1rem;
	margin-right: .5rem;
	font-size: 1rem;
	--border-opacity: 1;
	border-color: #fff;
	border-bottom: 2px;
	border-color: rgba(255, 255, 255, var(--border-opacity));
	border-style: solid
}

.hover-trigger .hover-target {
	display: none
}

.hover-trigger:hover .hover-target {
	display: block
}

.pageFadeIn {
	animation: pageFadeIn ease 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes pageFadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.pageInEffectUp {
	animation: pageInEffectUp ease 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes pageInEffectUp {
	0% {
		opacity: 0;
		transform: translateY(5px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.pageInEffectDown {
	animation: pageInEffectDown ease 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes pageInEffectDown {
	0% {
		opacity: 0;
		transform: translateY(-5px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.pageInEffectRight {
	animation: pageInEffectRight ease 0.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes pageInEffectRight {
	0% {
		opacity: 0;
		transform: translateX(-5px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.toastIn {
	animation: 0.3s ease toastIn 1;
}

.toastOut {
	animation: 3s ease toastOut 1;
	transform: translateX(100%);
	opacity: 0;
}

@keyframes toastIn {
	from {
		transform: translateX(100%);
		opacity: 0;
	}

	to {
		transform: translateX(0%);
		opacity: 1;
	}
}

@keyframes toastOut {
	from {
		transform: translateX(0%);
		opacity: 1;
	}

	10% {
		transform: translateX(0%);
		opacity: 1;
	}

	90% {
		transform: translateX(0%);
		opacity: 1;
	}

	to {
		transform: translateX(100%);
		opacity: 0;
	}
}
